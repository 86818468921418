<template>
  
</template>

<script>
export default {
  created () {
    // this.$router.push('/hfPage?id=HFTottori&l=tc')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-tottori-1-week-free-pass/tc', '_self')
  }
}
</script>

<style>

</style>